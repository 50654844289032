<template>
  <div class="socio-form vx-row">
    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        class="socios-table__prompt"
        :active.sync="activePromptVehicle"
        title="Edita el vehiculo"
        @accept="saveVehicle(input)"
        :is-valid="!invalid"
        accept-text="Guardar"
        cancel-text="Descartar"
      >
        <div class="w-100">
          <ValidationProvider
            name="Vehículo"
            v-slot="{ errors }"
            rules="required"
          >
            <vs-select
              class="w-full"
              v-model="input.vehicleType.id"
              placeholder="Seleccione vehículo"
              label="Vehículo"
            >
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.alias"
                v-for="(item, index) in vehicleTypes"
              />
            </vs-select>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="w-100">
          <ValidationProvider name="Placa" v-slot="{ errors }" rules="required">
            <vs-input
              label="Placa"
              placeholder="Placa"
              class="w-full"
              v-model="input.plate"
            ></vs-input>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="w-100">
          <ValidationProvider
            name="Revisión anual"
            v-slot="{ errors }"
            rules="required"
          >
            <label class="text-sm text-san-juan font-bold"
              >Revisión anual</label
            >
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="input.annual_revision"
            ></datepicker>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="w-100">
          <ValidationProvider name="Soat" v-slot="{ errors }" rules="required">
            <label class="text-sm text-san-juan font-bold">Soat</label>
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="input.soat"
            ></datepicker>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-prompt>
    </ValidationObserver>

    <div class="vx-col lg:w-8/12">
      <ValidationObserver v-slot="{ invalid }">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Código"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input class="w-full" label="Código" v-model="socio.code" />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-input
                class="w-full"
                label="Nº contrato"
                v-model="socio.agreement_number"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Nombre(s)"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Nombre(s)"
                  v-model="socio.user.first_name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Apellido(s)"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Apellido(s)"
                  v-model="socio.user.last_name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Tipo de documento"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  class="w-full"
                  label="Tipo de documento"
                  v-model="socio.document_type.id"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.document_name"
                    v-for="(item, index) in document_types"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Nº documento"
                v-slot="{ errors }"
                rules="required|numeric"
              >
                <vs-input
                  class="w-full"
                  label="Nº documento"
                  v-model="socio.document_number"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Télefono principal"
                v-slot="{ errors }"
                rules="required|phone"
              >
                <vs-input
                  class="w-full"
                  label="Télefono principal"
                  v-model="socio.user.phone"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <ValidationProvider
                name="Dirección"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Dirección"
                  v-model="socio.address"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 mb-2">
              <ValidationProvider
                name="Departamento"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  name="departamento"
                  label="Departamento"
                  placeholder="Seleccione su departamento"
                  v-model="departmentSelected"
                  class="w-full mt-2"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                    v-for="(item, index) in departamentos"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-2">
              <ValidationProvider
                name="Ciudad"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  name="ciudad"
                  label="Ciudad"
                  placeholder="Seleccione su ciudad"
                  v-model="citySelected"
                  class="w-full mt-2"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.name"
                    v-for="(item, index) in cities"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-2">
              <ValidationProvider
                name="Email"
                v-slot="{ errors }"
                rules="email"
              >
                <vs-input
                  class="w-full"
                  label="Email"
                  v-model="socio.user.email"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-2">
              <ValidationProvider
                name="Utilidad"
                v-slot="{ errors }"
                rules="min_value:1|max_value:100"
              >
                <vs-input
                  class="w-full"
                  label="Utilidad"
                  v-model.number="utility"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="vx-col sm:w-1/3 w-full mb-2">
              <ValidationProvider
                name="Nombre de la EPS"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Nombre de la EPS"
                  v-model="socio.eps"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/3 w-full mb-2">
              <ValidationProvider
                name="Nombre de la ARL"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Nombre de la ARL"
                  v-model="socio.arl"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/3 w-full mb-2">
              <ValidationProvider
                name="Fondo de pensiones"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-input
                  class="w-full"
                  label="Fondo de pensiones"
                  v-model="socio.pension_fund"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col w-full">
              <vs-textarea
                v-model="socio.observation"
                placeholder="Observaciones"
              ></vs-textarea>
            </div>
            <vs-divider></vs-divider>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="float-right">
                <vs-button
                  class="mr-3 mb-2"
                  @click="saveDeliveryman"
                  :disabled="invalid"
                  >Guardar</vs-button
                >
                <vs-button
                  @click="$router.go(-1)"
                  color="primary"
                  type="border"
                  class="mb-2"
                  >Descartar</vs-button
                >
              </div>
            </div>
          </div>
        </vx-card>
      </ValidationObserver>
    </div>

    <div class="vx-col w-full mt-5 lg:mt-0 lg:w-4/12">
      <vx-card class="w-full" title="Vehiculos">
        <vs-table stripe :data="socio.vehicles">
          <template slot="thead">
            <vs-th> Ref</vs-th>
            <vs-th> Tipo de vehiculo</vs-th>
            <vs-th> Placa </vs-th>
            <vs-th> Revición anual </vs-th>
            <vs-th> Soat </vs-th>
            <vs-th> Imagen </vs-th>
            <vs-th> Placa </vs-th>
            <vs-th> Editar </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="i" v-for="(tr, i) in socio.vehicles" :data="data">
              <vs-td>
                {{ tr.id }}
              </vs-td>
              <vs-td>
                {{ tr.vehicleType.alias }}
              </vs-td>
              <vs-td>
                {{ tr.plate }}
              </vs-td>
              <vs-td>
                {{ tr.annual_revision }}
              </vs-td>
              <vs-td>
                {{ tr.soat }}
              </vs-td>
              <vs-td>
                <feather-icon
                  v-if="tr.picture_url"
                  icon="ImageIcon"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="viewImage(tr.picture_url)"
                />
                <feather-icon
                  v-else
                  icon="AlertTriangleIcon"
                  svgClasses="h-5 w-5 mr-4 hover:text-nepal cursor-pointer"
                />
              </vs-td>
              <vs-td>
                <feather-icon
                  icon="ImageIcon"
                  v-if="tr.plate_picture_url"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="viewImage(tr.plate_picture_url)"
                />
                <feather-icon
                  v-else
                  icon="AlertTriangleIcon"
                  svgClasses="h-5 w-5 mr-4 hover:text-nepal cursor-pointer"
                />
              </vs-td>
              <vs-td>
                <feather-icon
                  icon="Edit3Icon"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="promptVehicle(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>

      <vx-card class="w-full mt-5" title="Imágenes">
        <section class="">
          <UpdateDeliverymanImage
            title="Foto del aliado"
            :deliverymanId="socio.id"
            :image="socio.profile_picture_url"
            field="profile_picture_url"
            :onUpdateImage="onUpdateDeliverymanImage"
            :onRemove="removeDeliverymanImage"
          />
          <UpdateDeliverymanImage
            title="Tarjeta de propiedad"
            :deliverymanId="socio.id"
            :image="socio.property_card_picture_url"
            field="property_card_picture_url"
            :onUpdateImage="onUpdateDeliverymanImage"
            :onRemove="removeDeliverymanImage"
          />
          <UpdateDeliverymanImage
            title="Foto de licencia"
            :deliverymanId="socio.id"
            :image="socio.drivers_license_picture_url"
            field="drivers_license_picture_url"
            :onUpdateImage="onUpdateDeliverymanImage"
            :onRemove="removeDeliverymanImage"
          />
          <UpdateDeliverymanImage
            title="Documento de identidad"
            :deliverymanId="socio.id"
            :image="socio.identification_document_picture_url"
            field="identification_document_picture_url"
            :onUpdateImage="onUpdateDeliverymanImage"
            :onRemove="removeDeliverymanImage"
          />
        </section>
      </vx-card>

      <vx-card class="w-full mt-5" title="Vehículos">
        <section
          v-for="vehicle of socio.vehicles"
          :key="vehicle"
          class="not:last:border-b mb-3 pb-2"
        >
          <p
            class="w-full rounded-lg bg-opacity-25 font-semibold py-2 text-xl text-san-juan"
          >
            <span class="text-san-juan font-bold">Ref: </span> {{ vehicle.id }}
          </p>
          <UpdateVehicleImage
            title="Foto de vehículo"
            :vehicle="vehicle"
            :image="vehicle.picture_url"
            field="picture_url"
            :onUpdateImage="onUpdateVehicleImage"
            :onRemove="removeVehicleImage"
          />
          <UpdateVehicleImage
            title="Foto de placa"
            :vehicle="vehicle"
            :image="vehicle.plate_picture_url"
            field="plate_picture_url"
            :onUpdateImage="onUpdateVehicleImage"
            :onRemove="removeVehicleImage"
          />
        </section>
      </vx-card>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { UPDATE_DELIVERYMAN } from '@/graphql/mutations';
import {
  DELIVERYMAN,
  DELIVERYMAN_DOCUMENT_TYPES,
  VEHICLE_TYPES,
} from '@/graphql/queries';
import citiesMixin from '@/mixins/cities-mixin';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import _ from 'lodash';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import UpdateDeliverymanImage from './UpdateDeliverymanImage.vue';
import UpdateVehicleImage from './UpdateVehicleImage.vue';

export default defineComponent({
  mixins: [citiesMixin],
  components: {
    Datepicker,
    es,
    UpdateDeliverymanImage,
    UpdateVehicleImage,
  },
  props: {
    socio: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    vehicleTypes: {
      query: VEHICLE_TYPES,
    },
  },
  mounted() {
    if (this.socio.city) {
      this.departmentSelected = this.socio.city.department.id;
      this.$nextTick(() => {
        this.citySelected = this.socio.city.id;
      });
    }
  },
  setup(props) {
    const { result: documentResult } = useQuery(DELIVERYMAN_DOCUMENT_TYPES);
    const document_types = useResult(
      documentResult,
      [],
      (data) => data.deliveryman_document_types
    );
    const state = reactive({
      langEs: es,
      activePromptVehicle: false,
      searchQuery: '',
      listVehicles: [],
      vehicle: {},
      input: {},
    });

    const utility = ref();

    onMounted(() => {
      utility.value = props.socio.utility * 100;
    });

    const viewImage = (value) => {
      window.open(value);
    };

    return {
      ...toRefs(state),
      document_types,
      utility,
      viewImage,
    };
  },
  methods: {
    promptVehicle(vehicle) {
      this.vehicle = vehicle;
      this.input = { ...vehicle };
      this.input.annual_revision = moment(this.input.annual_revision).toDate();
      this.input.soat = moment(this.input.soat).toDate();
      this.activePromptVehicle = true;
    },
    saveVehicle(input) {
      input.annual_revision = moment(input.annual_revision).format(
        'YYYY-MM-DD'
      );
      input.vehicle_type_id = input.vehicleType.id;
      input = _.omit(input, ['vehicleType', '__typename']);
      input.soat = moment(input.soat).format('YYYY-MM-DD');
      let vehicle = {
        id: this.socio.id,
        vehicles: {
          update: [
            {
              id: input.id,
              plate: input.plate,
              soat: input.soat,
              annual_revision: input.annual_revision,
              vehicle_type_id: input.vehicle_type_id,
            },
          ],
        },
      };
      this.updateVehicle(vehicle);
    },
    updateDeliveryman(socio) {
      const request = {
        id: socio.id,
        code: socio.code,
        agreement_number: socio.agreement_number,
        address: socio.address,
        observation: socio.observation,
        document_type_id: socio.document_type.id,
        utility: this.utility / 100,
        document_number: socio.document_number,
        city_id: this.citySelected,
        eps: socio.eps,
        arl: socio.arl,
        pension_fund: socio.pension_fund,
        vehicles: {
          update: socio.vehicles,
        },
        user: {
          update: {
            id: socio.user.id,
            first_name: socio.user.first_name,
            last_name: socio.user.last_name,
            phone: socio.user.phone,
            email: socio.user.email,
          },
        },
      };
      request.vehicles.update = request.vehicles.update.map(function (e) {
        const item = _.omit(e, ['__typename', 'vehicleType']);
        return item;
      });

      this.$apollo.provider.defaultClient
        .mutate({
          mutation: UPDATE_DELIVERYMAN,
          variables: {
            input: request,
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Socio Actualizado',
            text: 'El socio se ha actualizado correctamente.',
          });
        })
        .catch((error) => {
          Object.entries(error.graphQLErrors[0].extensions.validation).forEach(
            ([value]) => {
              this.$vs.notify({
                color: 'danger',
                title: 'No se pudo actualizar el socio',
                text: value[0],
              });
            }
          );
        });
    },
    updateVehicle(vehicle) {
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: UPDATE_DELIVERYMAN,
          variables: {
            input: vehicle,
          },

          update: (cache, { data: { updateDeliveryman } }) => {
            try {
              const data = cache.readQuery({
                query: DELIVERYMAN,
                variables: {
                  id: updateDeliveryman.id,
                },
              });
              cache.writeQuery({
                query: DELIVERYMAN,
                data,
                variables: {
                  id: updateDeliveryman.id,
                },
              });
            } catch (error) {
              console.error(error);
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Vehiculo Actualizado',
            text: 'El Vehiculo se ha actualizado correctamente.',
          });
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Vehiculo no actualizado',
            text: 'El Vehiculo no se ha actualizado correctamente.',
          });
        });
    },
    saveDeliveryman() {
      this.updateDeliveryman(this.socio);
    },

    removeDeliverymanImage(imageField) {
      this.socio[imageField] = null;
    },
    onUpdateDeliverymanImage(imageField, image) {
      this.socio[imageField] = image;
    },

    removeVehicleImage(vehicle, imageField) {
      vehicle[imageField] = null;
    },
    onUpdateVehicleImage(vehicle, imageField, image) {
      vehicle[imageField] = image;
    },
  },
});
</script>

<template>
  <section>
    <h6 class="mb-2">{{ title }}</h6>
    <div class="mb-4 flex gap-2">
      <picture v-if="image" class="relative">
        <img
          class="w-12 h-12 rounded-lg bg-cover"
          :src="image"
          alt="Profile picture url"
        />
        <span
          class="absolute top-0 w-12 h-12 hover:bg-opacity-50 bg-opacity-0 flex justify-center items-center rounded-lg transition-all duration-100 cursor-pointer bg-black"
        >
          <feather-icon
            icon="ImageIcon"
            svgClasses="h-12 w-12 p-3 text-gray hover:text-white transition-all duration-100"
            @click="viewImage(image)"
          />
        </span>
      </picture>
      <div
        v-else
        class="w-12 h-12 rounded-lg bg-cover border-2 border-gray border-dashed flex justify-center items-center"
      >
        <feather-icon
          icon="ImageIcon"
          svgClasses="h-12 w-12 p-3 text-gray transition-all duration-100"
        />
      </div>

      <vs-button
        color="primary"
        class="ml-auto font-bold"
        @click="updateVehicleImage"
      >
        <span v-if="!loadingUploadImage">Actualizar</span>
        <span v-else>...</span>
      </vs-button>
      <vs-button
        :disabled="!image"
        @click="removeVehicleImage"
        color="danger"
        class="font-bold"
        type="border"
      >
        <span v-if="!loadingRemoveImage">Quitar</span>
        <span v-else>...</span>
      </vs-button>
    </div>

    <vs-prompt
      :active.sync="showImportImagePrompt"
      class="modal"
      title="Cargar foto"
      @accept="importImage"
      @cancel="resetImagePrompt"
      accept-text="Cargar"
      cancel-text="Descartar"
    >
      <div>
        <h1 class="font-semibold text-lg mb-4">Sube un la imagen que desea</h1>
        <UploadImageModal
          :currentImage="currentImage"
          :onSuccess="onUploadImage"
        />
      </div>
    </vs-prompt>
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  REMOVE_VEHICLE_IMAGE,
  UPLOAD_VEHICLE_IMAGE,
} from '@/graphql/mutations';
import UploadImageModal from './modals/UploadImageModal.vue';

export default defineComponent({
  components: {
    UploadImageModal,
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
    image: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    field: {
      type: String,
      default: () => '',
    },
    onRemove: {
      type: Function,
    },
    onUpdateImage: {
      type: Function,
    },
  },

  setup({ vehicle, field, onRemove, onUpdateImage }, { root }) {
    const state = reactive({
      loadingRemoveImage: false,
      loadingUploadImage: false,
      showImportImagePrompt: false,
      currentImage: null,
    });

    const viewImage = (imageUrl) => {
      window.open(imageUrl);
    };

    const _removeVehicleImage = () => {
      state.loadingRemoveImage = true;
      root.$apollo.provider.defaultClient
        .mutate({
          mutation: REMOVE_VEHICLE_IMAGE,
          variables: {
            id: vehicle.id,
            image_field_to_remove: field,
          },
        })
        .then(() => {
          onRemove(vehicle, field);
          state.loadingRemoveImage = false;
          root.$vs.notify({
            color: 'success',
            title: 'Imagen eliminada',
            text: 'Se ha eliminado la imagen correctamente.',
          });
        })
        .catch(() => {
          state.loadingRemoveImage = false;
          root.$vs.notify({
            color: 'danger',
            title: 'Imagen no eliminada',
            text: 'No se ha podido eliminar la imagen del vehículo.',
          });
        });
    };

    const updateVehicleImage = () => {
      state.showImportImagePrompt = true;
    };

    const removeVehicleImage = () => {
      if (state.loadingRemoveImage) {
        return;
      }

      root.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar Eliminación`,
        text: `Eliminarás la imagen relacionada.`,
        accept: () => _removeVehicleImage(),
        acceptText: 'Eliminar',
      });
    };

    const onUploadImage = (image) => {
      state.currentImage = image;
    };
    const importImage = () => {
      if (!state.currentImage) {
        root.$vs.notify({
          color: 'warning',
          title: 'Imagen obligatoria',
          text: 'Debe haber cargado una imagen.',
        });
        return;
      }

      state.loadingUploadImage = true;
      root.$apollo.provider.defaultClient
        .mutate({
          mutation: UPLOAD_VEHICLE_IMAGE,
          variables: {
            id: vehicle.id,
            image_field_to_update: field,
            image: state.currentImage,
          },
        })
        .then(({ data }) => {
          onUpdateImage(vehicle, field, data.uploadVehicleImage[field]);
          state.currentImage = null;
          state.loadingUploadImage = false;
          root.$vs.notify({
            color: 'success',
            title: 'Imagen cargada',
            text: 'Se ha cargado la imagen correctamente.',
          });
        })
        .catch(() => {
          state.loadingUploadImage = false;
          root.$vs.notify({
            color: 'danger',
            title: 'Imagen no cargada',
            text: 'No se ha podido cargar la imagen del vehículo.',
          });
        });
    };

    const resetImagePrompt = () => {
      state.currentImage = null;
    };

    return {
      ...toRefs(state),
      viewImage,
      updateVehicleImage,
      removeVehicleImage,
      onUploadImage,
      importImage,
      resetImagePrompt,
    };
  },
});
</script>

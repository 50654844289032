<template>
  <div class="editar-socio">
    <vs-alert
      color="danger"
      title="Ally Not Found"
      :active.sync="socioNotFound"
    >
      <span>Ally record with id: {{ $route.params.socioId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'socios-logisticos' }"
          class="text-inherit underline"
          >All Allies</router-link
        >
      </span>
    </vs-alert>

    <SocioForm v-if="socio" :socio="socio" />
  </div>
</template>

<script>
import SocioForm from '@/components/socio/SocioForm.vue';
import { defineComponent } from '@vue/composition-api';
import { DELIVERYMAN } from '@/graphql/queries';
import { useQuery, useResult } from '@vue/apollo-composable';
export default defineComponent({
  setup(_, { root }) {
    const { result: deliverymanResult } = useQuery(DELIVERYMAN, {
      id: root.$route.params.socioId,
    });
    const socio = useResult(
      deliverymanResult,
      null,
      (data) => data.deliveryman
    );
    return { socio };
  },
  components: {
    SocioForm,
  },
  data() {
    return {
      partner: {
        id: '5ea86564031dfb2f38d8d30d',
        codigo: '1a6e2035-eb19-4e80-be0f-9bfa80b110db',
        cedula: '93cdd7e5-5f41-4642-9b95-beb80c3508f9',
        numeroContrato: 'e7a80468-a7ed-4f7d-ac9f-27467c821081',
        saldo: 1689,
        imagen: 'https://ui-avatars.com/api/?size=128',
        fullName: 'Jeri Mooney',
        firstName: 'Shawna',
        lastName: 'Guerrero',
        genero: 'female',
        email: 'shawnaguerrero@digial.com',
        phoneNumber: '+57 (824) 525-2904',
        secondPhoneNumber: '+57 (932) 403-2417',
        direccion: '154 Visitation Place, Kaka, Minnesota, 6830',
        descripcion:
          'Minim ullamco quis amet occaecat laborum dolore. Labore eu aute elit pariatur aute in. Enim fugiat exercitation et excepteur cillum magna ullamco veniam magna labore consectetur nostrud reprehenderit. Eiusmod culpa aliquip minim non consectetur sint fugiat sunt elit. Mollit culpa reprehenderit sunt magna deserunt aliquip eiusmod quis amet. Eu pariatur occaecat Lorem voluptate. Fugiat Lorem veniam ad aliqua.\r\n',
        created_at: '2017-04-21T07:09:35 +05:00',
        utilidad: 'elit',
        eps: 'irure',
        arl: 'esse',
        fondoPensiones: 'adipisicing',
        descuento: '$2,063.52',
        ahorro: '$3,406.85',
        montoSSO: '$2,946.26',
        placa: 'exercitation',
        soat: 'MUSIX',
        cdt: 'STEELFAB',
        franquicias: [
          {
            id: 0,
            name: 'Temorak',
          },
          {
            id: 1,
            name: 'Grainspot',
          },
          {
            id: 2,
            name: 'Comtract',
          },
        ],
        status: 'DES',
      },
      socioNotFound: false,
    };
  },
  created() {
    // this.$http
    //   .get('/api/socios/' + this.$route.params.socioId)
    //   .then(({ data }) => {
    //     this.socio = data;
    //   })
    //   .catch((err) => {
    //     if (err.response.status === 404) {
    //       this.socioNotFound = true;
    //       return;
    //     }
    //     console.error(err);
    //   });
  },
});
</script>

<style></style>

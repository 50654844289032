const citiesMixin = {
  data() {
    return {
      citySelected: '',
      departmentSelected: 68,
    };
  },
  computed: {
    departamentos() {
      return this.$store.state.cities.departments;
    },
    cities() {
      return this.$store.state.cities.cities;
    },
    coverageCities() {
      return this.$store.state.cities.coverageCities;
    },
  },
  watch: {
    departmentSelected(department) {
      if (department) {
        this.citySelected = '';
        this.selectedCity = '';
        this.$store.dispatch('cities/getCitiesByDepartment', department);
        this.$store.dispatch('cities/getCoveragesByDepartment', department);
      }
    },
  },
  async created() {
    await this.$store.dispatch('cities/getDepartments');
    if (this.departmentSelected && !this.citySelected) {
      this.citySelected = '';
      this.$store.dispatch(
        'cities/getCitiesByDepartment',
        this.departmentSelected
      );
      this.$store.dispatch(
        'cities/getCoveragesByDepartment',
        this.departmentSelected
      );
    }
  },
};

export default citiesMixin;
